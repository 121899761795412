<template>
<div class="work-list">
  <div class="work-list-top">
    <div class="tab">
      <div :class="item.id == curSubTab ? 'active' : ''" v-for="item in subTabList" :key="item.id" @click="changeSubTab(item)">
        <div >{{ item.name }}({{taskStat[item.countName]}})</div>
      </div>
    </div>
    <div class="top-r">
      <div class="top-r-desc">
        <p class="top-r-desc-tit">当前总任务待完成共{{taskStat.has_new_count}}个</p>
        <p>点击右侧【领取】按钮系统自动为您分配{{receive_count}}个任务</p>
      </div>
      <Button type="primary" @click="getTask">领取任务</Button>
    </div>
  </div>
  <div class="table">
    <div class="work">
<!--      <div class="search">-->
<!--        <Input suffix="ios-search" v-model="keyword" search :placeholder="$t('task_detail_placeholder')" style="width: auto" @on-search="getList" />-->
<!--      </div>-->

      <div class="table">
        <Table :columns="columns" :data="dataList" :loading="loading"></Table>
      </div>

      <div class="page">
        <Page :total="total" :current="page" @on-change="changePage" show-total />
      </div>
      <Modal
          v-model="resultModal"
          :title="$t('project_detail_data_work_result')"
          width="70%"
      >
        <ResultView :resultData="resultData" v-if="resultDataLen && resultModal"></ResultView>
        <div slot="footer"></div>
      </Modal>
      <Modal
          v-model="recordModal"
          :title="$t('project_detail_data_op_record')"
      >
        <Record :recordData="recordData"></Record>
        <div slot="footer"></div>
      </Modal>
      <Modal
          v-model="enResultModal"
          :title="$t('project_detail_data_work_result')"
          width="70%"
      >
        <EnResultView :resultData="resultData" v-if="resultDataLen && enResultModal"></EnResultView>
        <div slot="footer"></div>
      </Modal>
    </div>
  </div>
  <Modal
      v-model="confirmModal"
      title="确认"
  >
    <div>领取该任务后将默认放弃【编辑中】的剩余任务</div>
    <div slot="footer">
      <Button @click="confirmModal = false">取消</Button>
      <Button type="primary" @click="confirmGiveUp">确定放弃</Button>
    </div>
  </Modal>
</div>
</template>

<script>
import util from '@/utils/tools.js';
import ResultView from '@/components/production/category_question/result.vue'
import EnResultView from '@/components/production/category_question_en/enResult.vue'
import Record from '@/components/production/category_question/record.vue'

export default {
  name: "workList",
  data(){
    return{
      columns: [
        {
          title: this.$t('task_detail_work_id'),
          width:120,
          key: 'data_id'
        },
        {
          title: '题目',
          key: 'data_id',
          render:(h,params) =>{

            let title = '';
            if (params.row.dataResult && params.row.dataResult.data && params.row.dataResult.data.info && params.row.dataResult.data.info.title){
              //显示翻译-原文
              title = params.row.dataResult.data.info.title;
            }else if(params.row.dataResult && params.row.dataResult.data && params.row.dataResult.data['试题标题']){
              //显示试题-审题
              title = params.row.dataResult.data['试题标题'];
            }else if(params.row.dataResult && params.row.dataResult.result && params.row.dataResult.result.info && params.row.dataResult.result.info.title){
              //兼容出题
              title = params.row.dataResult.result.info.title;
            }else if(params.row.dataResult && params.row.dataResult.data && params.row.dataResult.data['课程内容']){
              //显示试题-出题
              title = params.row.dataResult.data['课程内容'];
            }
            return h('span',title);
          }
        },
        {
          title: '难度',
          width:120,
          render:(h,params) =>{
            return h('span',params.row.dataResult && params.row.dataResult.data && params.row.dataResult.data['难度等级'])
          }
        },
        {
          title: '题型',
          width:120,
          render:(h,params) =>{
            return h('span',params.row.dataResult && params.row.dataResult.data && params.row.dataResult.data['题型'] )
          }
        },
        // {
        //   title: this.$t('task_detail_work_status'),
        //   render:(h,params) =>{
        //     return h('span',this.statusesList[params.row.status])
        //   }
        // },
        // {
        //   title: '操作员',
        //   render:(h,params) =>{
        //     return h('span', params.row.user ? params.row.user.nickname + '('+params.row.user.id+')' : '')
        //   }
        // },
        // {
        //   title: this.$t('task_detail_date'),
        //   render:(h, params) => {
        //     return h('div', {},util.timeFormatter(
        //         new Date(+params.row.updated_at * 1000),
        //         'yyyy-MM-dd hh:mm:ss'
        //     ));
        //   }
        // },
        // {
        //   title: this.$t('task_detail_check_result'),
        //   key: 'address',
        //   render: (h, params) => {
        //     return h('div', [
        //       h('Button', {
        //         props: {
        //           type: 'primary',
        //           size: 'small',
        //           loading:params.row.resultLoading
        //         },
        //         on: {
        //           click: () => {
        //             this.showResult(params.row)
        //           }
        //         }
        //       }, this.$t('project_detail_data_result'))
        //     ]);
        //   }
        // },
        {
          title: this.$t('task_detail_op'),
          width:300,
          render: (h, para) => {
              //3 已提交 6 被驳回 7 挂起中 8 待复审
              let op;
              let btn;
              let btnType;

              // 未领取
              if (para.row.status === "0") {
                op = 'fetch';
                btn = this.$t('task_detail_worklist_fetch');
                btnType = 'warning';
              // 已领取 执行中
              } else if (para.row.status === "1" || para.row.status === '2') {
                op = 'fetch';
                btn = this.$t('task_detail_worklist_working');
                btnType = 'warning';
              //已提交
              } else if (para.row.status === '3' && para.row.is_refused !== '1' && para.row.is_refused !== '2') {
                op = 'redo';
                // btn = this.$t('task_detail_worklist_redo');
                btn = '撤回并修改作业';
                btnType = 'warning';
              } else if (para.row.status === '6') { //被驳回
                op = 'refused_revise';
                btn = this.$t('task_detail_worklist_refused_revise'); //修改
                btnType = 'warning';
              } else if (para.row.status === '7') { //挂起中
                op = 'difficult_revise';
                // stepType 0 执行 1 审核  2 质检 3 验收
                btn = this.$t('task_detail_worklist_difficult_revise'); // 需要判断
                btnType = 'primary';
              } else if (para.row.status === '8') { //待复审
                op = 'refusesubmit_receive';
                btn = this.$t('task_detail_worklist_refusesubmit_receive');
                btnType = 'primary';
              }
              return h('div', [
                op && btn ? h('Button',
                    {
                      props: {
                        type: btnType,
                        size: 'small',
                      },
                      style: {
                        margin: '5px'
                      },
                      on: {
                        click: () => {
                          this.operatorDataId = para.row.data.id;
                          this.curProjectId = para.row.project_id;
                          this.userId = para.row.user && para.row.user.id;
                          this.opName = op;
                          this.isBatch = false;
                          this.taskExecute();
                        }
                      }
                    }, btn) : '',
                h('Button',
                    {
                      props: {
                        size: 'small'
                      },
                      style: {
                        margin: '5px'
                      },
                      on: {
                        click: () => {
                          this.showRecord(para.row)
                        }
                      }
                    }, this.$t('operator_record')),
                h('Button', {
                  props: {
                    type: 'primary',
                    size: 'small',
                    loading:para.row.resultLoading
                  },
                  on: {
                    click: () => {
                      if(this.projectType == 2){
                        this.showResult(para.row,'enResult')
                      }else{
                        this.showResult(para.row,'result')
                      }

                    }
                  }
                }, this.$t('project_detail_data_result'))
              ]);
          }
        }
      ],
      dataList: [],
      total:0,
      page:1,
      pageSize:10,
      op:'0',
      // taskId:'',
      loading:false,
      statusesList:{},
      resultModal:false,
      resultLoading:false,
      resultData:{},
      recordModal:false,  //操作记录弹窗
      recordData:{},
      recordLoading:false,
      keyword:'',
      userId:'',
      curProjectId:'',
      operatorDataId:'',

      invalidList: [
        {
          value: 'New York',
          label: 'New York'
        },
        {
          value: 'London',
          label: 'London'
        },
        {
          value: 'Sydney',
          label: 'Sydney'
        },
        {
          value: 'Ottawa',
          label: 'Ottawa'
        },
        {
          value: 'Paris',
          label: 'Paris'
        },
        {
          value: 'Canberra',
          label: 'Canberra'
        }
      ],  //失效列表
      invalid: '',
      subTabList:[
        {
          name:'全部作业',
          id:'all',
          countName:'has_submit_count_byitems'
          // componentName:'WorkAll'
        },
        {
          name:'待完成',
          id:'new',
          countName:'has_new_count'
          // componentName:'WorkUnclaimed'
        },
        {
          name:'编辑中',
          id:'working',
          countName:'has_working_count'
          // componentName:'WorkOperation'
        },
        {
          name:'已提交',
          id:'submited',
          countName:'has_submit_count'
          // componentName:'WorkSubmit'
        },
        {
          name:'已通过',
          id:'finish',
          countName:'has_finish_count'
          // componentName:'WorkReceived'
        },
        // {
        //   name:'已失效',
        //   id:'invalid',
        //   // componentName:'WorkExpired'
        // },
        {
          name:'待修改',
          id:'refused',
          countName:'has_refused_count'
          // componentName:'WorkModified'
        },
        // {
        //   name:'挂起',
        //   id:'difficult',
        //   // componentName:'WorkPending'
        // },
        {
          name:'已返工',
          id:'refuse_submit',
          countName:'has_refuse_submited_count'
          // componentName:'WorkRework'
        },
        {
          name:'待返工',
          id:'refuse_wait',
          countName:'has_refuse_wait_count'
          // componentName:'WorkRework'
        },
      ],
      taskExecuteOps : {
        //对挂起的作业进行领取
        difficult_revise : 'difficult_revise',
        //对已领取或进行中的作业, 继续作业
        fetch : 'fetch',
          //对已提交的作业强制驳回作业
        redo: 'redo',
          //对被驳回的作业进行修改
        refused_revise: 'refused_revise',
        //对返工作业进行领取
        refusesubmit_receive: 'refusesubmit_receive',
      },
      curSubTab:'all',
      taskStat:{},
      projectType:'',
      enResultModal:false,
      confirmModal:false,
      opName:'',
      isBatch:false,
      // curComponent:'WorkAll',
    }
  },
  props:{
    projectId:{
      default:'',
      type:String
    },
    stepType:{
      default:'',
      type:String
    },
    taskId:{
      default:'',
      type:String
    },
    receive_count:{
      default:'',
      type:String
    }
  },
  watch:{
    taskId(newV){
      this.getList();
    }
  },
  computed:{
    resultDataLen(){
      return this.resultData.result && Object.keys(this.resultData.result).length;
    }
  },
  components:{
    ResultView,
    Record,
    EnResultView
  },
  created(){
    // this.taskId = this.$route.params.id;
    this.curSubTab = this.$route.query.subtab || 'all';
    // this.curComponent = this.subTabList.filter((item)=>{
    //   return item.id == this.curSubTab;
    // })[0].componentName;
    //this.formateWorkTabList(this.stepType);

    this.getList();
  },
  methods:{
    // formateWorkTabList(data){
    //   if(data != 0){
    //     let subtab = this.subTabList.findIndex((item)=>{
    //       return item.id == 'invalid'
    //     });
    //     this.subTabList.splice(subtab,1);
    //   }
    // },
    changeSubTab(item){
      this.curSubTab = item.id;
      // this.curComponent = item.componentName;
      this.$router.push({
        path: '/production/task',
        query: {
          tab: 'work-list',
          subtab: item.id
        },
      });
      this.getList();
    },
    getList(){
      let params = {
        project_id:this.projectId,
        task_id:this.taskId,
        page:this.page,
        pageSize:this.pageSize,
        op:this.curSubTab,
        keyword:this.keyword
      };
      this.loading = true;
      this.api.task.workList(params).then((res)=>{
        this.loading = false;

        this.dataList = res.list;
        this.dataList.forEach((item)=>{
          item.resultLoading = false;
          item.recordLoading = false;
        });
        this.total = Number(res.count);
        this.statusesList = res.statuses;
        this.taskStat = res.taskStat;
        this.projectType = res.project.category.type;
      }).catch((err)=>{
        this.loading = false;
      })
    },
    changePage(page){
      this.page = page;

      this.getList();
    },
    showRecord(data){ //操作记录
      this.recordData = {};
      this.getRecordDetail(data);
    },
    getRecordDetail(data){
      let params = {
        project_id:this.projectId,
        data_id:data.data_id
      };
      data.recordLoading = true;
      this.api.production.workRecords(params).then((res)=>{
        data.recordLoading = false;
        this.recordData = res;
        if(!Object.keys(this.recordData.list).length){
          this.$Message.warning(this.$t('project_detail_data_no_record'));
          return;
        }
        this.recordModal = true;
      }).catch((err)=>{
        data.recordLoading = false;
      })
    },
    showResult(data,type){ //作业结果
      // this.resultModal = true;
      this.resultData = {};
      this.getDataDetail(data,type);
    },

    getDataDetail(data,type){
      let params = {
        project_id:this.projectId,
        data_id:data.data_id
      };
      data.resultLoading = true;
      console.log(params,'params')
      this.api.production.dataDetail(params).then((res)=>{
        data.resultLoading = false;
        if(!res.info){
          this.$Message.warning(this.$t('project_detail_data_no_result'));
          return;
        }
        this.resultData = res.info.dataResult;

        if(!Object.keys(this.resultData.result).length){
          this.$Message.warning(this.$t('project_detail_data_no_result'));
          return;
        }
        if(type == 'enResult'){
          this.enResultModal = true;
        }else{
          this.resultModal = true;
        }

      }).catch((err)=>{
        data.resultLoading = false;
      })
    },
    taskExecute (type) {

      let params = {
        project_id: this.curProjectId,
        task_id: this.taskId,
        // user_id: this.userId,
        op: this.opName,
        data_id: this.operatorDataId,
      };
      if(type == 'clear_received'){
        params.clear_received = 1;
      }

      this.loading = true;
      this.api.task.taskExecute(params).then((res)=>{
        this.loading = false;
        if(res.op == 'clear_received'){
          this.confirmModal = true;
          return;
        }
        this.$router.push({
          path: '/production/task-execute',
          query: {
            project_id: this.curProjectId,
            task_id:this.taskId,
            data_id: this.operatorDataId,
          }
        });
      }).catch((err)=>{
        this.loading = false;
      })
    },
    confirmGiveUp(){
      if(!this.isBatch){
        this.taskExecute('clear_received');
      }else{
        this.batchTask('clear_received');
      }
    },
    getTask(){
      this.isBatch = true;
      this.batchTask();
    },
    batchTask (type) {

      let params = {
        project_id: this.projectId,
        task_id: this.taskId,
        // user_id: this.userId,
        op: 'fetch',
      };
      if(type == 'clear_received'){
        params.clear_received = 1;
      }

      this.api.task.taskExecute(params).then((res)=>{

        if(res.op == 'clear_received'){
          this.confirmModal = true;
          return;
        }
        this.$router.push({
          //name: 'perform-task',
          path: '/production/task-execute',
          query: {
            project_id: this.projectId,
            task_id: this.taskId
          }
        });
      })
    },
  }
}
</script>

<style scoped lang="scss">
.work-list{
  margin-top: 20px;
  .work-list-top{
    display: flex;
    justify-content: space-between;
    align-items: center;

    .tab{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border:1px solid #dcdee2;
      text-align: center;
      height: 46px;
      border-radius: 4px;
      overflow: hidden;
      font-size: 14px;
      cursor: default;
      line-height: 46px;

      >div{
        //width: 90px;
        padding: 0 10px;
        border-right:1px solid #dcdee2;
        cursor: pointer;
        height: 100%;
      }
      >div:last-child{
        border-right:none;
      }
      >div.active{
        background-color: #2d8cf0;
        color:#FFFFFF;
      }
    }

    .top-r{
      font-size: 14px;
      padding: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background-color: #f8f8f9;
      .top-r-desc{
        margin-right: 20px;
        .top-r-desc-tit{
          font-weight: bold;
        }
      }
    }
  }
}

.work{
  .search{
    //margin-top: -38px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .search-type{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-right: 10px;
      >span{
        margin-right: 10px;
      }
    }
  }
  .table{
    margin-top: 20px;
  }
  .page{
    margin-top:20px;
    text-align: right;
  }
}

</style>
