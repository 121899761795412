<template>
<div class="result">
  <div class="result-top">
    <div>
      <span>工作任务:</span>
      <span>{{ dataObj.data && dataObj.data['工作任务'] }}</span>
    </div>
    <div>
      <span>技能集:</span>
      <span>{{ dataObj.data && dataObj.data['技能集'] }}</span>
    </div>
    <div>
      <span>一级知识点:</span>
      <span>{{ dataObj.data && dataObj.data['技能点（一级知识点）'] }}</span>
    </div>
    <div>
      <span>题型:</span>
      <span>{{ dataObj.data && dataObj.data['题型'] }}</span>
    </div>
    <div>
      <span>难度等级:</span>
      <span>{{ dataObj.data && dataObj.data['难度等级'] }}</span>
    </div>
    <div>
      <span>知识点:</span>
      <span>{{knowledgePoint}}</span>
    </div>
    <div>
      <span>标签:</span>
      <span>{{ labels }}</span>
    </div>
    <div>
      <span>分类:</span>
      <span>{{ categorys }}</span>
    </div>
  </div>
  <div class="result-bot">
    <div class="result-info">
      <p class="tit">试题标题：</p>
      <Richtxt :dataObj="{val:dataObj.result.info.name}" :isInline="true"></Richtxt>
      <div class="options">
        <div class="options-item" v-for="(item,index) in dataObj.result.info.options" :key="index + 'option'">
          <span>{{nameList[index]}}</span>
          <Richtxt :dataObj="{val:item.name}" :isInline="true"></Richtxt>
          <Tag color="primary" style="margin-left: 10px;margin-top: -1px" v-if="item.is_correct == 1">正确</Tag>
        </div>
      </div>

      <p class="tit">解析：</p>
      <Richtxt :dataObj="{val:dataObj.result.info.explain}" :isInline="true"></Richtxt>
    </div>
    <div class="result-comment">
      <p class="result-comment-tit">历史评论：</p>
      <div class="result-comment-cont">
        <div class="result-comment-cont-tab">
          <p :class="curCommentIndex == index ? 'active' : ''" v-for="(item,index) in tabList" :key="index" @click="changeTab(index)">{{ item.name }}</p>
        </div>
        <div class="previous-comment-cont">
          <Timeline>
            <TimelineItem v-for="item in tabList[curCommentIndex].comment" :key="item.time">
              <div class="info">
                <p>{{ item.name }}</p>
                <p>{{ item.time }}</p>
                <Tag color="primary">{{ item.batch }}</Tag>
              </div>
              <p class="content color-b">{{ item.comment }}</p>
            </TimelineItem>
          </Timeline>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Richtxt from '../richtxt.vue';
export default {
  name: "result.vue",
  data(){
    return{
      dataObj:{},
      nameList:['A','B','C','D','E','F'],
      nodesName:'',
      knowledgePointList:[],
      knowledgePointLoading:false,
      knowledgePoint:'',
      categoryLoading:false,
      categoryList:[],
      labelLoading:false,
      labelList:[],
      page:1,
      pageSize:30,
      categorys:'',
      labels:'',
      tabList:[],
      curCommentIndex:0,
    }
  },
  props: {
    resultData:{
      type:Object,
      default:()=>{ return {}}
    }
  },
  watch:{
    resultData:{
      handler(newV){
        this.dataObj = newV;

        this.formate();

      },
      immediate:true,
    }
  },
  components:{
    Richtxt
  },
  methods:{
    changeTab(index){
      this.curCommentIndex = index;
    },
    formate(){
      // this.allData = {};
      // this.taskList.forEach((item)=>{
      //   let name = item.data.id;
      //   let options = [];
      //   let names = [this.$t('task_option_a'),this.$t('task_option_b'),this.$t('task_option_c'),this.$t('task_option_d'),this.$t('task_option_e'),this.$t('task_option_f')];
      //
      //   let result = item.dataResult.result;
      //
      //   if(result){
      //     result.info.options.forEach((sItem,index)=>{
      //       options.push({
      //         id:sItem.id,
      //         name:names[index],
      //         isCorrect:sItem.is_correct,
      //         val:sItem.name,
      //         comment:result.feedback ? result.feedback[sItem.id] : [],
      //         isCheck:false,
      //         isRich:false,
      //         placeholder:this.$t('task_option_placeholder'),
      //         newComment:''
      //       })
      //     })
      //
      //     let title = {
      //       val:result.info.name,
      //       comment:result.feedback ? result.feedback.name : [],
      //       isCheck:false,
      //       isRich:false,
      //       placeholder:this.$t('task_title_placeholder'),
      //       newComment:''
      //     };
      //     let analysis = {
      //       val:result.info.explain,
      //       comment:result.feedback ? result.feedback.explain : [],
      //       isCheck:false,
      //       isRich:false,
      //       placeholder:this.$t('task_analysis_placeholder'),
      //       newComment:''
      //     };
      //     this.$set(this.allData,name,{});
      //
      //     this.$set(this.allData[name],'title',title);
      //     this.$set(this.allData[name],'analysis',analysis);
      //     this.$set(this.allData[name],'optionList',options);
      //   }
      //
      //
      // })
      this.tabList = [];
      this.tabList.push({
        name:'题干',
        comment:this.dataObj.result.feedback && this.dataObj.result.feedback.name ? this.dataObj.result.feedback.name : []
      })
      this.dataObj.result.info.options.forEach((item,index)=>{
        this.tabList.push({
          name:'选项' + this.nameList[index],
          comment:this.dataObj.result.feedback && this.dataObj.result.feedback[item.id] ? this.dataObj.result.feedback[item.id] : []
        })
      })
      this.tabList.push({
        name:'解析',
        comment:this.dataObj.result.feedback && this.dataObj.result.feedback.explain ? this.dataObj.result.feedback.explain : []
      })

      let nodeIds = this.dataObj.result.info.nodes;
      this.getKnowledgePoint(nodeIds,'ids');

      let categoryIds = this.dataObj.result.info.categories ? Object.values(this.dataObj.result.info.categories).join(',') : '';
      this.getCategory(categoryIds,'ids');

      let labels = this.dataObj.result.info.labels ? Object.values(this.dataObj.result.info.labels).join(',') : '';
      this.getLabel(labels,'ids');

    },
    getKnowledgePoint(data,type){
      let pramas = {
        page:this.page,
        pageSize:this.pageSize,
      };

      pramas.keyword = data;

      this.knowledgePointLoading = true;
      this.api.dataset.datasetNodeList(pramas).then((res)=>{
        this.knowledgePointLoading = false;

        this.knowledgePointList = res.list;

        this.knowledgePoint = this.knowledgePointList.filter((item)=>{
          return item.id == this.dataObj.result.info.nodes;
        })[0].name
      }).catch((err)=>{
        this.knowledgePointLoading = false;
      })
    },
    getCategory(data,type){
      let pramas = {
        page:this.page,
        pageSize:this.pageSize,
      };

      pramas.keyword = data;

      this.categoryLoading = true;
      this.api.dataset.categoryList(pramas).then((res)=>{
        this.categoryLoading = false;

        this.categoryList = res.list;

        let categoryIds = Object.values(this.dataObj.result.info.categories)

        this.categorys = this.categoryList.filter((item)=>{
          let len = categoryIds.filter((sItem)=>{
            return sItem == item.id
          }).length;

          if(len) return item;
        }).map((item)=>{
          return item.name
        }).join(',')
      }).catch((err)=>{
        this.categoryLoading = false;
      })
    },
    getLabel(data,type){
      let pramas = {
        page:this.page,
        pageSize:this.pageSize,
      };

      pramas.keyword = data;

      this.labelLoading = true;
      this.api.dataset.labelList(pramas).then((res)=>{
        this.labelLoading = false;

        this.labelList = res.list;

        let labelIds = Object.values(this.dataObj.result.info.labels)

        this.labels = this.labelList.filter((item)=>{
          let len = labelIds.filter((sItem)=>{
            return sItem == item.id
          }).length;

          if(len) return item;
        }).map((item)=>{
          return item.name
        }).join(',')
      }).catch((err)=>{
        this.labelLoading = false;
      })
    },
  }
}
</script>

<style scoped lang="scss">
.result{
  padding: 20px;
  font-size: 14px;
  .result-top{
    padding-bottom: 20px;
    border-bottom: 1px solid #e8eaec;
    font-size: 14px;
    line-height: 28px;
    >div{
      >span:nth-child(1){
        font-weight: bold;
      }
    }
  }
  .result-bot{
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;

    .result-info{
      flex: 1;
      margin-right: 40px;
      height: 300px;
      overflow-y: auto;
      .tit{
        margin-bottom: 10px;
        font-weight: bold;
      }
      .options{
        margin: 40px 0;

        .options-item{
          margin-bottom: 10px;
          display: flex;
          justify-content: flex-start;
          >span{
            margin-right: 10px;
          }
        }
        .active{
          color:#2d8cf0;
        }
      }
    }
    .result-comment{
      flex: 1;

      .result-comment-tit{
        margin-bottom: 10px;
        font-weight: bold;
      }
      .result-comment-cont{
        padding: 20px;
        background-color: #f8f8f9;
        border-radius: 6px;

        .result-comment-cont-tab{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 40px;
          border-bottom: 1px solid #dcdee2;
          >p{
            width: 60px;
            text-align: center;
            cursor: pointer;
          }
          .active{
            font-weight: bold;
            color:#2d8cf0;
          }
        }
        .previous-comment-cont{
          margin-top: 20px;
          padding-left: 20px;
          height: 200px;
          overflow-y: auto;
          .info{
            //margin-top: -5px;
            display: flex;
            justify-content: flex-start;
            //align-items: center;
            >p:nth-child(2){
              margin: 0 20px;
            }
          }
          .color-b{
            color:#2d8cf0;
          }
        }
      }
    }
  }
}
</style>
