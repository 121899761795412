<template>
  <div class="result">
    <div class="result-top">
      <div class="result-info">
        <p class="tit">试题标题：</p>
        <Richtxt :dataObj="{val:dataObj.data.info.name}" :isInline="true" :isDisabled="true"></Richtxt>
        <div class="options">
          <div class="options-item" v-for="(item,index) in dataObj.data.info.options" :key="index + 'option'">
            <span>{{nameList[index]}}</span>
            <Richtxt  :dataObj="{val:item.name}" :isInline="true" :isDisabled="true"></Richtxt>
<!--            <Tag color="primary" style="margin-left: 10px;margin-top: -1px" v-if="item.is_correct == 1">正确</Tag>-->
          </div>
        </div>

        <p class="tit">解析：</p>
        <Richtxt :dataObj="{val:dataObj.data.info.explain}" :isInline="true" :isDisabled="true"></Richtxt>
      </div>
      <div class="result-info">
        <p class="tit">Test title：</p>
        <Richtxt :dataObj="{val:dataObj.result.info.name}" :isInline="true" :isDisabled="true"></Richtxt>
        <div class="options">
          <div class="options-item" v-for="(item,index) in dataObj.result.info.options" :key="item.id + 'option'">
            <span>{{nameList[index]}}</span>
            <div class="options-item-nr">
              <Richtxt :dataObj="{val:item.name}" :isInline="true" :isDisabled="true"></Richtxt>
            </div>

<!--            <Tag color="primary" style="margin-left: 10px;margin-top: -1px" v-if="item.is_correct == 1">正确</Tag>-->
          </div>
        </div>

        <p class="tit">解析：</p>
        <Richtxt :dataObj="{val:dataObj.result.info.explain}" :isInline="true" :isDisabled="true"></Richtxt>
      </div>
    </div>
    <div class="result-bot">

      <div class="result-comment">
        <p class="result-comment-tit">历史评论：</p>
        <div class="result-comment-cont">
          <div class="result-comment-cont-tab">
            <p :class="curCommentIndex == index ? 'active' : ''" v-for="(item,index) in tabList" :key="index" @click="changeTab(index)">{{ item.name }}</p>
          </div>
          <div class="previous-comment-cont">
            <Timeline>
              <TimelineItem v-for="item in tabList[curCommentIndex].comment" :key="item.time">
                <div class="info">
                  <p>{{ item.name }}</p>
                  <p>{{ item.time }}</p>
                  <Tag color="primary">{{ item.batch }}</Tag>
                </div>
                <p class="content color-b">{{ item.comment }}</p>
              </TimelineItem>
            </Timeline>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Richtxt from '../richtxt.vue';
export default {
  name: "result.vue",
  data(){
    return{
      dataObj:{},
      nameList:['A','B','C','D','E','F'],
      nodesName:'',
      knowledgePointList:[],
      knowledgePointLoading:false,
      knowledgePoint:'',
      categoryLoading:false,
      categoryList:[],
      labelLoading:false,
      labelList:[],
      page:1,
      pageSize:30,
      categorys:'',
      labels:'',
      tabList:[],
      curCommentIndex:0,
    }
  },
  props: {
    resultData:{
      type:Object,
      default:()=>{ return {}}
    }
  },
  watch:{
    resultData:{
      handler(newV){
        this.dataObj = newV;

        this.formate();

      },
      immediate:true,
    }
  },
  components:{
    Richtxt
  },
  methods:{
    changeTab(index){
      this.curCommentIndex = index;
    },
    formate(){
      console.log(this.resultData,'resultDataresultDataresultData')
      this.tabList = [];
      this.tabList.push({
        name:'题干',
        comment:this.dataObj.result.feedback && this.dataObj.result.feedback.name ? this.dataObj.result.feedback.name : []
      })
      this.dataObj.result.info.options.forEach((item,index)=>{
        this.tabList.push({
          name:'选项' + this.nameList[index],
          comment:this.dataObj.result.feedback && this.dataObj.result.feedback[item.id] ? this.dataObj.result.feedback[item.id] : []
        })
      })
      this.tabList.push({
        name:'解析',
        comment:this.dataObj.result.feedback && this.dataObj.result.feedback.explain ? this.dataObj.result.feedback.explain : []
      })



    },
  }
}
</script>

<style scoped lang="scss">
.result{
  padding: 20px;
  font-size: 14px;
  .result-top{
    padding-bottom: 20px;
    border-bottom: 1px solid #e8eaec;
    font-size: 14px;
    line-height: 28px;
    display: flex;
    justify-content: flex-start;

    >div{
      flex: 1;
      height: 300px;
      overflow-y: auto;
      >span:nth-child(1){
        font-weight: bold;
      }
    }
    >div:first-child{
      margin-right: 20px;
    }
    .result-info{
      flex: 1;
      margin-right: 40px;
      height: 300px;
      overflow-y: auto;

      .options{
        margin: 40px 0;

        .options-item{
          margin-bottom: 10px;
          display: flex;
          justify-content: flex-start;
          >span{
            margin-right: 10px;
          }
          .options-item-nr{
            flex: 1;
            width: 0;
          }
        }
        .active{
          color:#2d8cf0;
        }
      }
    }
  }
  .tit{
    margin-bottom: 10px;
    font-weight: bold;
  }
  .result-bot{
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;


    .result-comment{
      flex: 1;

      .result-comment-tit{
        margin-bottom: 10px;
        font-weight: bold;
      }
      .result-comment-cont{
        padding: 20px;
        background-color: #f8f8f9;
        border-radius: 6px;

        .result-comment-cont-tab{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 40px;
          border-bottom: 1px solid #dcdee2;
          >p{
            width: 60px;
            text-align: center;
            cursor: pointer;
          }
          .active{
            font-weight: bold;
            color:#2d8cf0;
          }
        }
        .previous-comment-cont{
          margin-top: 20px;
          padding-left: 20px;
          height: 200px;
          overflow-y: auto;
          .info{
            //margin-top: -5px;
            display: flex;
            justify-content: flex-start;
            //align-items: center;
            >p:nth-child(2){
              margin: 0 20px;
            }
          }
          .color-b{
            color:#2d8cf0;
          }
        }
      }
    }
  }
}
</style>
