<template>
<div class="record">
  <div class="record-item" v-for="(item,index) in dataList" :key="item.id">
    <div class="record-item-time">2023.04.03 16:56:25</div>
    <div class="record-item-line">
      <div class="record-item-line-fgx" v-if="index <dataList.length - 1"></div>
      <div class="record-item-line-top" :class="index== 0 ? 'record-item-line-top-f' : ''"></div>
    </div>
    <div class="record-item-info">
      <div class="record-item-info-name">{{ item.afterUser && item.afterUser.nickname }}</div>
      <div class="record-item-info-step" :class="index== 0 ? 'record-item-info-step-b' : ''">{{ item.step.name }}</div>
      <div class="record-item-info-op">操作：{{typesList[item.type] }}</div>
    </div>
  </div>
<!--  <Table :columns="columns" :data="dataList"></Table>-->
</div>
</template>

<script>
import util from '@/utils/tools.js';
export default {
  name: "record",
  data(){
    return {
      dataList:[],
      // columns: [
      //   {
      //     title: '操作员',
      //     key: 'afterUser.nickname',
      //     render: (h, params) => {
      //       return h('div', {},params.row.afterUser && params.row.afterUser.nickname);
      //     }
      //   },
      //   {
      //     title: '工序',
      //     key: 'step.name',
      //     render: (h, params) => {
      //       return h('div', {},params.row.step.name);
      //     }
      //   },
      //   {
      //     title: '操作类型',
      //     key: 'type',
      //     render: (h, params) => {
      //       return h('div', {},this.typesList[params.row.type]);
      //     }
      //   },
      //   {
      //     title: '前操作员',
      //     key: 'beforeUser',
      //     render: (h, params) => {
      //       return h('div', {},params.row.beforeUser && params.row.beforeUser.nickname);
      //     }
      //   },
      //   {
      //     title: '前作业状态',
      //     key: 'before_work_status',
      //     render: (h, params) => {
      //       return h('div', {},this.workStatusList[params.row.before_work_status]);
      //     }
      //   },
      //   {
      //     title: '后作业状态',
      //     key: 'after_work_status',
      //     render: (h, params) => {
      //       return h('div', {},this.workStatusList[params.row.after_work_status]);
      //     }
      //   },
      //   {
      //     title: '操作时间',
      //     key: 'updated_at',
      //     render:(h, params) => {
      //       return h('div', {},util.timeFormatter(
      //           new Date(+params.row.updated_at * 1000),
      //           'yyyy-MM-dd hh:mm:ss'
      //       ));
      //     }
      //   }
      // ],
      typesList:{},
      workStatusList:{},
    }
  },
  props: {
    recordData:{
      type:Object,
      default:()=>{ return {}}
    }
  },
  watch:{
    recordData:{
      handler(newV){
        this.dataList = newV.list;
        this.typesList = newV.types
        this.workStatusList = newV.work_status

      },
      immediate:true,
    }
  },
  methods:{

  }
}
</script>

<style scoped lang="scss">
.record{
  padding: 30px 30px 0 30px;
  max-height: 500px;
  overflow-y: auto;
  .record-item{
    display: flex;
    justify-content: flex-start;
    font-size: 14px;
    height: 60px;
    .record-item-time{
      margin-top: -5px;
      width: 160px;
    }
    .record-item-line{
      height: 100%;
      width: 13px;
      position: relative;
      .record-item-line-fgx{
        position: absolute;
        left: 6px;
        top: 0;
        width: 1px;
        height: 100%;
        background-color: #e8eaec;
      }
      .record-item-line-top{
        width: 12px;
        height: 12px;
        border: 1px solid #e8eaec;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: #FFFFFF;
      }
      .record-item-line-top-f{
        background-color: #2d8cf0;
      }
    }
    .record-item-info{
      margin-top: -5px;
      margin-left: 20px;
      display: flex;
      justify-content: flex-start;

      .record-item-info-name{
        width: 60px;
        font-size: 16px;
        font-weight: bold;
      }
      .record-item-info-step{
        margin: -3px 15px;
        width: 50px;
        text-align: center;
        border-radius: 6px;
        background-color: #c5c8ce;
        color:#FFFFFF;
        height: 30px;
        line-height: 30px;
      }
      .record-item-info-step-b{
        background-color: #2d8cf0;
        color:#FFFFFF;
      }
      .record-item-info-op{
        width: 100px;
      }
    }
  }
}
</style>
